@import './styles/variables';

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $ghost;
  background-color: $jet;
  box-sizing: border-box;

  h2 {
    font-size: 32px;
    margin-bottom: 40px;
  }

  * {
    box-sizing: border-box;
  }
}

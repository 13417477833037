// Palette
$tagita-green: #076300;
$ghost: #fff;
$ash: #7f7e7a;
$jet: #2f3035;
$jungle: #0d2818;

// Shadows
$shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);

// Spaces
$header-height: 120px;

.paslaugos {
  background-image: url('./../Gallery/galerija_18.jpg');
}

.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 20px;
}

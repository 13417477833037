@import '../../styles/variables';

.service {
  padding: 20px 25px 40px;
  width: 20%;

  @media screen and (max-width: 1200px) {
    width: 33%;
  }

  @media screen and (max-width: 870px) {
    width: 50%;
  }

  @media screen and (max-width: 530px) {
    width: 100%;
  }
}

.icon {
  width: 130px;
  height: 130px;
  margin: 0 auto 10px;

  &.placeholder {
    border: 2px solid $ghost;
    border-radius: 50%;
  }
}

.title {
  font-size: 20px;
  font-weight: 400;
}

.description {
  font-size: 18px;
}

@import '../../styles/variables';

.apieMus {
  background-image: url('./../Gallery/galerija_19.jpg');
}

.information {
  text-align: center;
  max-width: 1300px;
  margin: 30px auto 70px;
  padding: 0 120px;
  font-size: 25px;

  @media screen and (max-width: 530px) {
    padding: 0 20px;
  }
}

@import '../../styles/variables';

.pagrindinis {
  background-color: $ghost;
  min-height: 100vh;
  padding-top: $header-height;
  position: relative;
  z-index: 0;
}

.textWrapper,
.button {
  position: relative;
  z-index: 5;
}

.textWrapper {
  margin-top: 130px;

  @media screen and (max-width: 530px) {
    margin-top: 30px;
  }
}

.title {
  max-width: 785px;
  margin: 0 auto;
  padding: 0 15px;
  color: $jet;
  text-transform: uppercase;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.7);
  letter-spacing: 2px;

  @media screen and (max-width: 530px) {
    font-size: 26px;
  }
}

.button {
  margin-top: 35px;
  padding: 20px;
  display: inline-block;
  background-color: $jungle;
  color: $ghost;
  box-shadow: $shadow;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  letter-spacing: 2px;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: $tagita-green;
  }
}

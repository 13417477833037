@import '../../styles/variables';

.footerWrapper {
  background-image: url('./../Gallery/galerija_8.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
}

.footer {
  position: relative;
  background-color: rgba($ghost, 0.6);
  backdrop-filter: blur(5px);
  color: $jet;
  font-size: 18px;

  p {
    margin: 0 0 5px;
  }

  a {
    color: $jet;
  }
}

.bold {
  font-weight: 700;
}

.text {
  position: relative;
  text-align: center;
  padding: 50px 10px 0;
  z-index: 1;
}

.kpf {
  height: 100px;
  margin: 30px 0 120px;
}

.triangles {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 250px;
  margin: 0;
  align-self: flex-end;
  z-index: 0;

  @media screen and (max-width: 530px) {
    height: 150px;
  }
}

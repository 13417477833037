@import '../../styles/variables';

.header {
  background-color: rgba($ghost, 0.6);
  position: fixed;
  top: 0;
  height: $header-height;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  z-index: 6;

  &:not(.unblur) {
    backdrop-filter: blur(5px);
  }

  @media screen and (max-width: 1030px) {
    height: ($header-height * 0.75);
  }
}

.logoLink,
.link {
  text-decoration: none;
  color: $jet;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  position: relative;
  cursor: pointer;

  &.active {
    color: $tagita-green;
  }

  &.active,
  &:hover {
    &:after {
      transform: scaleX(1);
      transform-origin: 0 50%;
    }
  }
}

.link {
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    width: calc(100% - 40px);
    height: 1px;
    background-color: $tagita-green;
    transform: scaleX(0);
    transform-origin: 100% 50%;
    transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  }
}

.logo {
  height: $header-height;
  width: 260px;
  margin-left: ($header-height / 2);
  background-image: url(./tagita.png);
  background-size: contain;
  background-repeat: no-repeat;

  @media screen and (max-width: 1225px) {
    margin-left: ($header-height / 4);
  }

  @media screen and (max-width: 1030px) {
    height: ($header-height * 0.75);
  }
}

.number,
.linkText {
  display: inline-block;
  margin: 0 8px;
  padding: 8px;
  font-size: 22px;
  text-transform: uppercase;

  @media screen and (max-width: 1225px) {
    margin: 0 5px;
    padding: 5px;
    font-size: 20px;
  }
}

.number {
  color: $tagita-green;
  font-weight: 700;

  @media screen and (max-width: 1225px) {
    font-size: 18px;
  }
}

.menu {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: ($header-height / 2);
  padding: 8px;
  justify-content: center;

  @media screen and (max-width: 1225px) {
    margin-right: ($header-height / 4);
  }

  @media screen and (max-width: 1030px) {
    height: initial;
    width: 100%;
    position: absolute;
    flex-direction: column;
    align-items: flex-end;
    top: 0;
    right: 0;
    z-index: 1;
    margin-right: 0;
    padding-bottom: 50px;

    .toggle {
      display: block;
      outline: 0;
    }

    .number,
    .linkText {
      font-size: 25px;
      display: none;
    }

    &.show {
      background-color: rgba($ash, 0.7);
      backdrop-filter: blur(5px);

      .lineOne {
        margin-top: -1px;
        transform: rotate(45deg);
      }

      .lineTwo {
        margin-top: -1px;
        transform: rotate(-45deg);
      }

      .number,
      .linkText {
        display: inline-block;
      }
    }
  }
}

.toggle {
  display: none;
  position: relative;
  width: 30px;
  height: 30px;
  margin: 20px;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      .lineOne,
      .lineTwo {
        margin-top: -1px;
        background-color: $tagita-green;
      }
    }
  }
}

.lineOne,
.lineTwo {
  position: absolute;
  width: 30px;
  height: 3px;
  border-radius: 2px;
  background-color: $jet;
  transition: all 0.3s ease-in-out;
}

.lineOne {
  margin-top: -3px;
}

.lineTwo {
  margin-top: 7px;
}

@import '../../styles/variables';

.linkArrow {
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  width: 50px;
  height: 50px;
  margin-left: 50%;
  transform: translate(-50%);
  background-color: $tagita-green;
  color: $ghost;
  box-shadow: $shadow;
  border-radius: 50%;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: $jungle;
  }
}

@import '../../styles/variables';

// Configurable values
$pictureCount: 3;
$slideDuration: 4;

:export {
  pictureCount: $pictureCount;
}

$LOOP_DURATION: $pictureCount * $slideDuration + 0s;

@for $i from 1 through $pictureCount {
  .picture#{$i} {
    background-image: url(./galerija_#{$i}.jpg);
    animation: growThenHide $LOOP_DURATION linear ($LOOP_DURATION * (($i - 1) / $pictureCount)) infinite;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 4;
  }
}

.galerija {
  position: absolute;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.smoke {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $ghost;
  opacity: 0.2;
  z-index: 5;
}

@keyframes growThenHide {
  0%,
  33.333% {
    opacity: 1;
  }

  41.667%,
  100% {
    opacity: 0;
    transform: scale(1.1);
    z-index: 4;
  }
}

@import '../../styles/variables';

.kontaktuForm {
  text-align: left;
  max-width: 1300px;
  margin: 20px auto;
  padding: 0 30px 100px;
  font-size: 20px;

  & :global(.MuiFormLabel-root) {
    color: $ghost;
    margin-bottom: 15px;
  }

  & :global(.MuiFormLabel-root.Mui-focused) {
    color: $ghost;
  }

  & :global(.MuiFormControlLabel-root) {
    margin-bottom: 15px;
  }
}

.kontaktuSection {
  border: 1px solid rgba($ghost, 0.75);
  border-radius: 4px;
}

.kontaktuInformation {
  margin-left: 50px;
  font-size: 22px;

  @media screen and (max-width: 960px) {
    margin: 50px 0 0 0;
  }

  a {
    color: $ghost;
  }
}

.requiredInfoText {
  font-style: italic;
  margin-bottom: 30px;
}

.formControl {
  min-width: 150px;
}

.wholeFormError {
  font-size: 20px;
  color: #f44336;
}

.success {
  font-size: 20px;
  color: $ghost;
  background-color: $tagita-green;
  padding: 10px;
  margin: 20px 0 0;
  border-radius: 4px;
  text-align: center;
}

@import '../../styles/variables';

.sectionWrapper {
  min-height: 100vh;
  padding: $header-height 0 30px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $jet;
    opacity: 0.7;
  }
}

.content {
  position: relative;

  h2 {
    letter-spacing: 1px;
  }
}
